<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_billingAchievementList" />
      <!-- 請求実績一覧画面 -->
      <v-form ref="form">
        <v-container fluid>
          <v-row class="search-row">
            <div class="search-row-exeBtn">
              <!-- 取引先-->
              <div class="search-autocomplete first-search-item" style="float: left; width: 450px">
                <v-autocomplete
                  dense
                  v-model="suppliersSelected"
                  :items="supplierList"
                  :label="$t('label.lbl_supplier')"
                  :hint="setSrhSupplier()"
                  :rules="[rules.required]"
                  class="txt-single"
                  persistent-hint
                ></v-autocomplete>
              </div>
              <span class="require asterisk-spacer" style="float: left">*</span>

              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 請求No(業務No) -->
              <div class="serch-textbox-arrivalNo" style="float: left; width: 450px">
                <v-autocomplete
                  dense
                  v-model="billingBussinessNoSelected"
                  :items="billingBussinessNoList"
                  :label="$t('label.lbl_billingBussinessNo')"
                  :hint="setBillingBussinessNo()"
                  class="txt-single"
                  persistent-hint
                ></v-autocomplete>
              </div>

              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>

              <!-- 請求月度-->
              <!-- カレンダー -->
              <!-- カレンダーfrom-->
              <div class="date-style" style="float: left; margin-left: 12px">
                <v-text-field
                  outlined
                  dense
                  v-model="billingMonth"
                  :label="$t('label.lbl_billingMonth')"
                  @change="changeDate"
                  @click:clear="billingMonthCal = null"
                  :rules="[rules.yyyymm]"
                  class="txt-single"
                  clear-icon="mdi-close-circle"
                  clearable
                ></v-text-field>
              </div>
              <!--カレンダーボタン領域-->
              <div class="calender-btn-area" style="float: left">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="-100"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  class="date-calendar"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" text x-small>
                      <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="billingMonthCal"
                    type="month"
                    @input="dateMenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-btn id="btn-prevMonth" class="day-ctrl-btn" @click="prevMonth" text x-small>
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn id="btn-nextMonth" class="day-ctrl-btn" @click="nextMonth" text x-small>
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>

              <!-- 空白 -->
              <span class="item-spacer" style="float: left">&nbsp;</span>
            </div>
          </v-row>

          <!--ボタン領域-->
          <v-row>
            <div class="search-row-exeBtn">
              <!--検索ボタン領域-->
              <div style="float: left">
                <!-- 検索ボタン-->
                <v-btn color="primary" class="api-btn" @click="search">
                  {{ $t("btn.btn_search") }}
                </v-btn>
                <!--画面クリアボタン-->
                <v-btn class="other-btn" @click="clearScreen()">{{ $t("btn.btn_clear") }}</v-btn>
              </div>
              <div style="float: right">
                <!-- 新規登録ボタン-->
                <v-btn ref="btnInquiry" color="primary" class="other-btn" @click="goInitRegist">
                  {{ $t("btn.btn_iniRegist") }}
                </v-btn>
              </div>
            </div>
          </v-row>

          <v-row>
            <div class="display-search-result-row">
              <!--検索結果表示-->
              <div class="text-label" style="float: left">
                <span class="toatlNum">総件数：{{ totalCount }}件</span>
              </div>

              <!--表示件数切替部分-->
              <div style="float: right">
                <!--表示件数-->
                <div class="text-label" style="clear: both; float: left">
                  {{ $t("label.lbl_itemsPerPage") }}
                </div>

                <!-- 空白 -->
                <span class="item-spacer" style="float: left">&nbsp;</span>

                <!--表示件数切替ボタン-->
                <div style="float: left">
                  <v-btn-toggle
                    v-model="itemsPerPageBtn"
                    color="blue darken-2"
                    class="toggle black--text"
                    right
                  >
                    <v-btn class="pasing-btn" value="10" @click="u_10_on()" small>
                      {{ 10 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="50" @click="u_10_clear(50)" small>
                      {{ 50 }}
                    </v-btn>
                    <v-btn class="pasing-btn" value="100" @click="u_10_clear(100)" small>
                      {{ 100 }}
                    </v-btn>
                  </v-btn-toggle>
                </div>
              </div>
            </div>
          </v-row>
        </v-container>
      </v-form>
      <!-- データテーブル -->
      <v-container fluid>
        <v-data-table
          id="listData"
          fixed-header
          :headers="headerItems"
          :items="inputList"
          :page.sync="page"
          :server-items-length="itemsPerPage"
          :options.sync="sortOptions"
          disable-filtering
          :hide-default-footer="true"
          height="530px"
        >
          <!-- 月度 -->
          <template v-slot:[`item.month`]="{ item }">
            <div class="align-left">{{ item.month }}</div>
          </template>

          <!-- 請求No -->
          <template v-slot:[`item.billingNo`]="{ item }">
            <div class="align-left">{{ item.billingNo }}</div>
          </template>

          <!-- 伝票No -->
          <template v-slot:[`item.billingSlipNo`]="{ item }">
            <div class="align-left">{{ item.billingSlipNo }}</div>
          </template>

          <!-- 請求金額 -->
          <template v-slot:[`item.billingAmount`]="{ item }">
            <div class="align-right">{{ item.billingAmount }}</div>
          </template>

          <!-- 詳細 -->
          <template v-slot:[`item.detail`]="{ item }">
            <v-btn small @click="goUpdate(item)" text>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-container>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
      />
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
      <!-- エラーメッセージ -->
      <v-dialog v-model="errDialog" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>警告</v-card-title>
          <v-card-text class="pa-4">
            <p>{{ alertMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="errOkClicked"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import SimpleDialog from "@/components/SimpleDialog";
import sideMenu from "@/components/Menu";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { i18n } from "../../lang/lang.js";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import ConfirmDialog from "@/components/ConfirmDialog";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
export default {
  name: appConfig.MENU_ID.P_BIL_APPROVAL,
  components: {
    Loading,
    SimpleDialog,
    ConfirmDialog,
    sideMenu,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  data: () => ({
    index: 0,
    // ローディング画面表示フラグ
    loadingCounter: 0,
    u_10: false,
    singleSelect: false,
    pListPopup: false,
    alertMessage: "",
    // ページング
    itemsPerPageList: [10, 50, 100],
    page: 1,
    pageCount: 0,
    totalCount: 0,
    itemsPerPage: 10,
    itemsPerPageBtn: "10",
    // 戻る遷移か
    back: false,
    // 編集対象インデックス
    editedIndex: "",
    // 取引先(value)
    suppliersSelected: "",
    // 取引先(text)
    suppliersNm: "",
    // 請求コード(value)
    billingBussinessNoSelected: "",
    // 請求コード(text)
    billingBussinessNoNm: "",
    // メニュー
    openMenu: null,
    // 入荷予定日付カレンダー
    dateMenu: false,
    // カレンダーfrom
    billingMonthCal: "",
    billingMonth: "",
    // 検索値保持用
    searchedSuppliers: "",
    searchedBillingBussinessNo: "",
    searchedBillingMonth: "",
    searchedBillingMonthCal: "",
    // 初期データ
    defaultData: [],
    // ヘッダ
    headerItems: [
      // 月度
      {
        text: i18n.tc("label.lbl_Month"),
        value: "month",
        width: "15%",
        align: "left",
        sortable: true,
      },
      // 請求No
      {
        text: i18n.tc("label.lbl_billingNo"),
        value: "billingNo",
        width: "25%",
        align: "left",
        sortable: true,
      },
      // 伝票No
      {
        text: i18n.tc("label.lbl_billingSlipNo"),
        value: "billingSlipNo",
        width: "25%",
        align: "left",
        sortable: true,
      },
      // 請求金額
      {
        text: i18n.tc("label.lbl_billingAmount"),
        value: "billingAmount",
        width: "25%",
        align: "right",
        sortable: true,
      },
      // 詳細
      {
        text: i18n.tc("label.lbl_Detail"),
        value: "detail",
        width: "10%",
        align: "center",
        sortable: false,
      },
    ],
    // ソート
    sortItem: "",
    sortOptions: {},
    sortyyyymmMap: {},
    sortBillingNoMap: {},
    sortBillingSlipNoMap: {},
    sortBillingAmountMap: {},
    inputList: [],
    // 取引先リストボックス
    supplierList: [],
    billingBussinessNoList: [],
    maxMonth: "",
    rules: {
      required: (value) => !!value || i18n.tc("check.chk_input"),
      yyyymm: (value) =>
        value == null ||
        value == "" ||
        (!!value &&
          value.length == 7 &&
          !!value.match(/^[0-9]{4}\/(0[1-9]|1[0-2])$/) &&
          new Date(value.split("/")[0] + "-" + value.split("/")[1]) != "Invalid Date") ||
        i18n.tc("check.chk_billingCutOffMonth"),
    },
    ConfirmDialog: {
      message: "",
      redMessage: "",
      okAction: () => {},
      isOpen: false,
    },
    infoDialog: {
      isOpen: false,
      title: "",
      message: "",
    },
    // エラー用ダイアログ
    errDialog: false,
  }),
  methods: {
    //  テーブルフッターの高さ変更
    // ページング
    u_10_check() {
      if (this.itemsPerPage < 11) {
        this.u_10 = true;
      }
    },
    u_10_on() {
      this.u_10 = true;
      this.itemsPerPage = 10;
      this.page = 1;
      this.search();
    },
    u_10_clear(val) {
      this.u_10 = false;
      this.itemsPerPage = val;
      this.page = 1;
      this.search();
    },
    // 初期化
    init() {
      // 初期データ保持
      this.defaultData = Object.assign({}, this.$data);

      // リストボックス取得処理
      this.getCustomInfo();

      this.inputList = [];
      this.billingMonthCal = "";
      this.itemsPerPage = 10;
      this.itemsPerPageBtn = "10";

      // 戻る遷移の場合、前画面からの情報を設定する
      this.back = this.$route.params.back;
      if (this.back && !this.clearFlg) {
        this.suppliersSelected = !this.isEmpty(this.$route.params.searchedSuppliers)
          ? this.$route.params.searchedSuppliers
          : "";
        this.billingBussinessNoSelected = !this.isEmpty(
          this.$route.params.searchedBillingBussinessNo
        )
          ? this.$route.params.searchedBillingBussinessNo
          : "";
        this.billingMonthCal = !this.isEmpty(this.$route.params.searchedBillingMonth)
          ? this.$route.params.searchedBillingMonth
          : "";
        if (!this.isEmpty(this.suppliersSelected)) {
          this.search();
        }
        // 遷移フラグ
        this.transitionFlg = true;
      }

      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },

    // リストボックス取得
    getCustomInfo() {
      this.suppliersSelected = "";
      this.billingType = "";
      this.txt_billingBussinessNo = "";
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );

      Promise.all([clientList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.supplierList = result[0];
          // 取引先が1件の場合、取引先を自動選択する
          this.supplierList.length == 1
            ? (this.suppliersSelected = this.supplierList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // 業務Noリスト
    getBussinessNoList() {
      if (this.suppliersSelected == null || this.suppliersSelected == "") {
        return;
      }
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 業務No
      const bussinessNoList = getParameter.getBizSpecList("0", this.suppliersSelected);

      Promise.all([bussinessNoList])
        .then((result) => {
          // 画面の初期値を設定します。
          this.billingBussinessNoList = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // リストボックス取得
    getMaxMonth() {
      if (this.back) {
        this.back = false;
        return;
      }
      // ローディング画面表示ON
      this.loadingCounter = 1;

      // 取引先
      const list = getParameter.getMaxMonth(this.suppliersSelected);

      Promise.all([list])
        .then((result) => {
          // 画面の初期値を設定します。
          this.billingMonthCal = this.formatDateDelimiter(result[0].yyyyMm, "", "-", "");
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.screenFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },

    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    // 検索ボタン
    search() {
      let valid = false;

      /**
       * 登録画面からの遷移の場合、入力チェックを実施するとvalidateが定義されていないと
       * エラーがでるので回避するため、このようなコードとなっている。
       */
      if (this.back) {
        // 登録画面からの遷移の場合、入力チェックは実施しない。
        valid = true;
      } else {
        // 上記外の場合はチェックを行う。
        valid = this.$refs.form.validate();
      }

      if (valid) {
        this.loadingCounter = 1;

        const config = this.$httpClient.createGetApiRequestConfig();
        // 営業所SID（ログインユーザ情報）
        config.params.officeSid = sessionStorage.getItem("sales_office_sid");
        // 取引先SID
        config.params.clientSid = this.suppliersSelected;
        // 請求No
        config.params.businessNo = this.billingBussinessNoSelected;
        // 請求月度
        config.params.yyyyMm = !this.isEmpty(this.billingMonthCal)
          ? this.formatDateDelimiter(this.billingMonthCal, "-")
          : "";
        // ページング
        if (this.page >= 2 && this.inputList.length == 0) {
          config.params.reqComPageIndex = "1";
          this.page = 1;
        } else {
          config.params.reqComPageIndex = this.page;
        }

        config.params.reqComPaginationFlg = "1";
        config.params.reqComPageLimit = this.itemsPerPage;
        // ソート
        if (this.sortItem != "") {
          config.params.reqComSortItem = this.sortItem;
        }
        // console.debug("search() Config", config);
        return new Promise((resolve, reject) => {
          this.$httpClient
            // 接続先のAPI_URLを入力
            // TODO API名
            .secureGet(appConfig.API_URL.BIZ_BIL_ACTUAL, config)
            .then((response) => {
              // console.debug("search() Response", response);
              const jsonData = JSON.parse(JSON.stringify(response.data));

              // 正常時
              if (jsonData.resCom.resComCode == "000") {
                // ページング
                this.pageCount = jsonData.resCom.resComPagination.totalPage;
                this.totalCount = jsonData.resCom.resComPagination.totalRecord;

                const list = [];
                jsonData.resIdv.invoiceAct.forEach((row) => {
                  list.push({
                    month: this.formatDateDelimiter(row.yyyyMm, "", "年", "月"), // 月度
                    billingNo: row.businessNo, // 請求No
                    billingSlipNo: this.formatInvListNo(row.invListNo), // 伝票No
                    billingAmount: this.parseNumberFormat(row.aoumntMoney), // 金額
                  });
                });
                this.inputList = list;
                resolve(response);
                // エラーじゃなければ、検索値保持
                this.searchedSuppliers = this.suppliersSelected;
                this.searchedBillingBussinessNo = this.billingBussinessNoSelected;
                this.searchedBillingMonth = this.billingMonthCal;

                // エラー時
              } else {
                this.infoDialog.message = jsonData.resCom.resComMessage;
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.screenFlag = true;
                reject();
              }
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.screenFlag = true;
              reject();
            })
            .finally(() => {
              // ローディング画面表示OFF
              this.loadingCounter = 0;
            });
        });
      }
    },

    // 新規登録ボタン押下
    goInitRegist() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.suppliersSelected || !this.billingBussinessNoSelected || !this.billingMonth) {
        this.errDialog = true;
        this.alertMessage =
          i18n.tc("label.lbl_supplier") +
          "、" +
          i18n.tc("label.lbl_billingBussinessNo") +
          "、" +
          i18n.tc("label.lbl_billingMonth") +
          i18n.tc("check.chk_input_variable");
      } else {
        this.alertMessage = "";
        this.getTextName(this.billingBussinessNoSelected);
        this.getInsertName();

        // 請求実績登録変更画面（新規登録）へ遷移
        this.$router.push({
          name: appConfig.SCREEN_ID.P_BIL_005,
          params: {
            // 新規登録フラグ
            newFlg: true,
            supplier: this.suppliersSelected,
            supplierNm: this.suppliersNm,
            billingBussinessNo: this.billingBussinessNoSelected,
            billingBussinessNoNm: this.billingBussinessNoNm,
            billingMonth: this.billingMonth,
            // 戻り遷移時の検索値、新規登録時は入力値を渡す
            searchedSuppliers: this.suppliersSelected,
            searchedBillingBussinessNo: this.billingBussinessNoSelected,
            searchedBillingMonth: this.billingMonthCal,
          },
        });
      }
    },

    // 詳細ボタン押下
    goUpdate(item) {
      // 選択した行のアイテム
      this.editedIndex = this.inputList.indexOf(item);
      this.getTextName(item.billingNo);

      // 請求実績登録変更画面（変更）へ遷移
      this.$router.push({
        name: appConfig.SCREEN_ID.P_BIL_005,
        params: {
          // 新規登録フラグ
          newFlg: false,
          supplier: this.searchedSuppliers,
          supplierNm: this.suppliersNm,
          billingBussinessNo: item.billingNo,
          billingBussinessNoNm: this.billingBussinessNoNm,
          billingSlipNo: item.billingSlipNo,
          billingMonth: item.month ? item.month.replaceAll("年", "/").replaceAll("月", "") : "",
          // 戻り遷移時の検索値、詳細時は検索値を渡す
          searchedSuppliers: this.searchedSuppliers,
          searchedBillingBussinessNo: this.searchedBillingBussinessNo,
          searchedBillingMonth: this.searchedBillingMonth,
        },
      });
    },

    //新規登録用処理
    getInsertName() {
      // 取引先名と取引先コードを取得
      for (var k = 0; k < this.supplierList.length; k++) {
        if (this.supplierList[k].value == this.suppliersSelected) {
          this.suppliersNm = this.supplierList[k].text;
        }
      }
    },

    // 次画面表示用の名称取得
    getTextName(billingBussinessNo) {
      // 取引先名と取引先コードを取得
      for (var k = 0; k < this.supplierList.length; k++) {
        if (this.supplierList[k].value == this.searchedSuppliers) {
          this.suppliersNm = this.supplierList[k].text;
        }
      }

      // 業務Noを取得
      for (var j = 0; j < this.billingBussinessNoList.length; j++) {
        if (this.billingBussinessNoList[j].value == billingBussinessNo) {
          this.billingBussinessNoNm = this.billingBussinessNoList[j].text;
        }
      }
    },

    // アラートダイアログクローズ
    errOkClicked() {
      this.errDialog = false;
    },

    // カレンダコンポーネントの日付はyyyy-MMのためyyyy/MMに変換する。
    formatDate(date) {
      if (!date) return null;
      date = commonUtil.zen2han(date);
      // 月の正規表現（yyyy/MM/dd）
      const regex = /^[0-9]{4}\/(0[1-9]|[1-9]|1[0-2])$/;
      date = date.replaceAll("／", "/");
      // 対象の文字列チェック
      if (regex.test(date)) {
        return date;
      } else {
        // 上記以外の形で入ってきた場合は、スラッシュ編集で返却（データピッカーで日付を選んだ場合）
        const [year, month] = date.split("-");
        return `${year}/${month}`;
      }
    },

    // 日付形式を変換する。
    formatDateDelimiter(date, delimiter, changeDelimiter1 = "", changeDelimiter2 = "") {
      if (!date) return null;
      // 月の正規表現（yyyyMM）
      const regex = /^[0-9]{4}(0[1-9]|[1-9]|1[0-2])$/;
      const regex2 = new RegExp("([0-9]{4})" + delimiter + "(0[1-9]|[1-9]|1[0-2])");
      date = commonUtil.zen2han(date);
      // 対象の文字列チェック
      if (regex.test(date)) {
        return date.slice(0, 4) + changeDelimiter1 + date.slice(4) + changeDelimiter2;
      } else if (regex2.test(date)) {
        return date.replaceAll(delimiter, changeDelimiter1);
      } else {
        return date;
      }
    },

    // 請求伝票No変換処理
    formatInvListNo(slipNo) {
      if (slipNo === void 0 || !slipNo) return "";
      let slipSliceNo = slipNo;
      if (slipNo.length > 6) {
        slipSliceNo = slipSliceNo.slice(0, 6);
      }

      return slipSliceNo;
    },

    // 請求月度を－１月します。
    prevMonth() {
      if (this.billingMonthCal == null || this.billingMonthCal == "") {
        return;
      }
      let date = new Date(this.billingMonthCal);
      this.billingMonthCal = dateTimeHelper.dateCalcYyyyMM(date.setMonth(date.getMonth() - 1));
    },

    // 請求月度を＋１月します。
    nextMonth() {
      if (this.billingMonthCal == null || this.billingMonthCal == "") {
        return;
      }
      let date = new Date(this.billingMonthCal);
      this.billingMonthCal = dateTimeHelper.dateCalcYyyyMM(date.setMonth(date.getMonth() + 1));
    },

    // 日付入力
    changeDate(val) {
      var strDt = null;
      var inputVal = commonUtil.zen2han(val);
      if (inputVal.length == 6) {
        strDt = inputVal.substr(0, 4) + "-" + inputVal.substr(4, 2);
      } else {
        strDt = inputVal.substr(0, 4) + "-" + inputVal.substr(5, 2);
      }
      if (strDt != null && strDt.length == 7 && new Date(strDt) != "Invalid Date") {
        this.billingMonthCal = strDt;
      } else if (this.isEmpty(val)) {
        this.billingMonthCal = val;
      }
    },

    // 取引先コードリストボックス取得処理
    setSrhSupplier() {
      for (let i = 0; i < this.supplierList.length; i++) {
        if (this.supplierList[i].value == this.suppliersSelected) {
          return this.supplierList[i].name;
        }
      }
    },

    // 請求No(業務No)リストボックス取得処理
    setBillingBussinessNo() {
      for (let i = 0; i < this.billingBussinessNoList.length; i++) {
        if (this.billingBussinessNoList[i].value == this.billingBussinessNoSelected) {
          return this.billingBussinessNoList[i].name;
        }
      }
    },

    /**
     * カンマ付きの数字に変換する
     * @param {*} value 変換対象の値
     */
    parseNumberFormat(value) {
      if (!value) return "¥" + 0;
      value = String(value);
      let delimiter = ",";
      let len = value.length;
      let delimiterPoint = 3;
      if (len <= delimiterPoint) {
        return "¥" + value;
      }
      let numOfDeliimiter =
        len % delimiterPoint == 0 ? len / delimiterPoint - 1 : len / delimiterPoint;

      for (let i = 1; i <= numOfDeliimiter; i++) {
        let index = len - delimiterPoint * i;
        let previousTxt = value.slice(0, index);
        let behindTxt = value.slice(index);
        value = previousTxt + delimiter + behindTxt;
      }
      return "¥" + value;
    },

    // 項目が空かチェック
    isEmpty(value) {
      if (value === undefined || value === null || value === "") {
        return true;
      }
      return false;
    },

    /**
     * 画面クリアボタン押下時の処理
     */
    clearScreen() {
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_clear"),
      ]);
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.clearScreenOkClick;
    },
    /**
     * 画面クリア
     */
    clearScreenOkClick() {
      // 初期表示の設定
      Object.assign(this.$data, this.defaultData);
      // クリアフラグ
      this.clearFlg = true;
      // 初期化
      this.init();
      // 画面遷移後の場合
      if (this.transitionFlg) {
        this.back = false;
        this.transitionFlg = false;
      }
      // バリデーションチェックをリセット
      this.$refs.form.resetValidation();
    },
  },
  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
    billingMonthCal(val) {
      this.billingMonth = this.formatDate(val);
    },
    suppliersSelected(newValue) {
      // 画面クリア時の場合
      if (this.clearFlg) {
        this.clearFlg = false;
        return;
      }
      // 遷移後
      if (this.$route.params.searchedSuppliers) {
        this.getBussinessNoList();
        this.getMaxMonth();
        return;
      }
      //請求No.クリア
      this.billingBussinessNoSelected = "";
      this.billingBussinessNoList = [];
      if (newValue || null) {
        this.suppliersSelected = newValue;
        this.getBussinessNoList();
        this.getMaxMonth();
      } else {
        this.suppliersSelected = newValue;
        this.getBussinessNoList();
        this.getMaxMonth();
      }
    },
    page: function (newValue) {
      this.page = newValue;
      this.search();
    },
    sortOptions: {
      handler(items) {
        // console.debug("watch sortByApi", items);
        if (items.sortBy.length < 1) {
          this.sortItem = "";
        } else {
          var sortBy = items.sortBy[0];
          var ascdesc = items.sortDesc[0] ? " desc" : " asc";
          this.sortItem = appConfig.P_BIL_002_SORT[sortBy] + ascdesc;
          this.search();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
.from_to {
  font-weight: bold;
  // padding-top: 8px;
  // margin-left: 0;
  // margin-right: 0;
}
.toatlNum {
  margin-left: 10px;
}
.amountArea {
  margin-right: auto;
  margin-left: 0;
  margin-top: 20px;
  font-size: x-large;
  font-weight: bold;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}
// テーブルフッターの高さ調整
// .minheight {
//   margin-bottom: 0px;
// }
</style>
